const appConfig = {
    apiPrefix: 'https://api-siscar3.sisplani.com',
    authenticatedEntryPath: '/ventas-ticket',//incio despues del login
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/app/account/kyc-form',
    locale: 'en',
    enableMock: false,

    sisplaniApi: 'https://api-siscar3.sisplani.com',
    cuentasxCobrarSoles : '1720',
    cuentasxPagarSoles : '1739',
    cuentasxPagarDolaresMonedaOriginal:'1740',
    cuentasxPagarDolaresMonedaOriginalSoles:'1740'

}

export default appConfig
